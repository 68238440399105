import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="additional-tools"
export default class extends Controller {
  
  checkCampaignNotes() {
    var campaign_notes_panel = document.getElementById('campaign_notes')
    if(!campaign_notes_panel.classList.contains('hidden')){
      if(document.getElementById('selected_campaign')){
        var campaign_id = document.getElementById('selected_campaign').value
        var option = document.getElementById('unread_' + campaign_id)
        if(option !== null && option.classList.contains('unread')){
          $.ajax({
            method: "POST",
            url: "/campaigns/" + campaign_id + "/update_read_message",
            headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
            success: function (data) {
              if(data.conversations.length > 0) {
                option.text = option.text.substring(2)
                option.classList.remove('unread')

                var remove = true
                var option_list = document.getElementById('selected_campaign').options
                for(var i = 0; i < option_list.length; i++){
                  if(option_list[i].classList.contains('unread')) {
                    remove = false
                  }
                }

                if(remove == true){
                  document.getElementById('campaign_unread_messages').remove()
                }

              } else {
                option.text = option.text.substring(2)
                option.classList.remove('unread')
                document.getElementById('campaign_unread_messages').remove()
              }
            }
          });
        }
      }
    }
  }

  
  connect() {
    document.controllerRef = this

    if(this.element.id == 'additionalToolsOffCanvas'){
      var offcanvas = document.getElementById('additionalToolsOffCanvas')
      window.additionalToolsOC = new bootstrap.Offcanvas(offcanvas)
    }

    if(this.element.id == 'additionalToolsOffCanvas' || this.element.id == 'campaignConversationHolder'){
      this.checkCampaignNotes()  
    }
  }

  editMessage(event) {
    event.preventDefault()
    var item = event.currentTarget
    document.getElementById('new_campaign_conversation').classList.add('hidden')
    document.getElementById('edit_campaign_conversation').classList.remove('hidden')

    document.getElementById('campaign_conversation_update_message').value = item.dataset.message
    document.getElementById('campaign_conversation_id').value = item.dataset.id
  }

  hideAdditionalTools(event) {
    event.preventDefault()
    this.resetAdditionalNavPanel(event.currentTarget)
    window.currentAdditionalStatus = ''
  }

  loadCampaignNotes(event) {
    event.preventDefault()

    var item = event.currentTarget
    var display = this.resetAdditionalNavPanel(item)
    window.currentAdditionalStatus = item.dataset.id

    if(display == 'show') {
      document.getElementById('campaign_notes').classList.remove("hidden")
      $(item).parent().addClass('active')

      document.controllerRef.checkCampaignNotes()
    }
  }

  loadPersonalNotes(event) {
    event.preventDefault()

    var item = event.currentTarget
    var display = this.resetAdditionalNavPanel(item)
    window.currentAdditionalStatus = item.dataset.id

    if(display == 'show') {
      document.getElementById('personal_notes').classList.remove("hidden")
      $(item).parent().addClass('active')
    }
  }

  loadSupportPanel(event) {
    event.preventDefault()

    var item = event.currentTarget
    var display = this.resetAdditionalNavPanel(item)
    window.currentAdditionalStatus = item.dataset.id

    if(display == 'show') {
      document.getElementById('admin_support').classList.remove("hidden")
      $(item).parent().addClass('active')
    }
  }


  resetAdditionalNavPanel(event) {
    $('[data-bs-toggle="tooltip"]').each(function(){
      $(this).tooltip('hide')
    })

    // Hide all panels
    const panels = document.getElementsByClassName('additional-tools')
    for (let i = 0; i < panels.length; i++) {
      panels.item(i).classList.add('hidden')
    }

    // Reset all nav buttons
    const buttons = document.getElementsByClassName('additional-tool-nav-item')
    for (let i = 0; i < buttons.length; i++) {
      buttons.item(i).classList.remove('active')
    }

    var nav = document.getElementById('additionalToolsOffCanvas')
    if(event.dataset !== undefined && (window.currentAdditionalStatus != event.dataset.id || !nav.classList.contains('show'))) {
      window.additionalToolsOC.show()
      return 'show'
    } else {
      window.additionalToolsOC.hide()
      return 'hide'
    }
  }


  toggleAdditionalTools(event) {
    var status = ''
    var item = event.currentTarget
    var btn = document.getElementById('additional_tools_toggle')
    var nav = document.getElementById('additional_tools')
    if(nav.classList.contains('hidden')){
      nav.classList.remove('hidden')
      btn.classList.add('expanded')
      item.innerHTML = '<em class="fa fa-chevron-right"></em>'
      item.title = I18n.additional_tools.hide_side_panel
      item.setAttribute("data-bs-original-title", I18n.additional_tools.hide_side_panel)
      item.setAttribute("aria-label", I18n.additional_tools.hide_side_panel)
      status = 'show'

      var w = document.getElementById('content-wrapper').offsetWidth - 50
      document.getElementById('content-wrapper').style.width = w + "px"
      
      let cro = document.getElementById('configureReportOptions')
      if( cro != null ) {
        cro.classList.add('has-sidepanel')
      }

      let rso = document.getElementById('wj_report-settings-offcanvas')
      if( rso != null ) {
        rso.classList.add('has-sidepanel')
      }
    } else {
      nav.classList.add('hidden')
      btn.classList.remove('expanded')
      item.innerHTML = '<em class="fa fa-chevron-left"></em>'
      item.title = I18n.additional_tools.show_side_panel
      item.setAttribute("data-bs-original-title", I18n.additional_tools.show_side_panel)
      item.setAttribute("aria-label", I18n.additional_tools.show_side_panel)
      status = 'hide'

      var w = document.getElementById('content-wrapper').offsetWidth + 50
      document.getElementById('content-wrapper').style.width = w + "px"

      let cro = document.getElementById('configureReportOptions')
      if( cro != null ) {
        cro.classList.remove('has-sidepanel')
      }

      let rso = document.getElementById('wj_report-settings-offcanvas')
      if( rso != null ) {
        rso.classList.remove('has-sidepanel')
      }

      this.resetAdditionalNavPanel(event)
    }

    $.ajax({
      method: "POST",
      url: "/users/toggle_additional_tools_status",
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      data: { 'status': status },
    })
  }


  toggleNotesEditor(e) {
    e.preventDefault()
    var item = e.currentTarget

    if(item.dataset.show == 'editor') {
      document.getElementById('personal_note_holder').classList.add('hidden')
      document.getElementById('personal_note_editor').classList.remove('hidden')
      item.dataset.show = 'notes'
      item.innerHTML = I18n.additional_tools.view_notes
    } else if(item.dataset.show == 'notes') {
      document.getElementById('personal_note_holder').classList.remove('hidden')
      document.getElementById('personal_note_editor').classList.add('hidden')
      item.dataset.show = 'editor'
      item.innerHTML = I18n.additional_tools.edit_notes
    }
  }

  verifyDelete(event) {
    event.preventDefault()
    var item = event.currentTarget

    $('[data-bs-toggle="tooltip"]').each(function(){
      $(this).tooltip('hide')
    })

    var ans = confirm(I18n.additional_tools.delete_message)
    if(ans){
      $.ajax({
        type: "DELETE",
        url: "/campaigns/" + item.dataset.id + "/delete_conversation",
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
        success: function (data) {
          if(data.status == 200) {
            var elem = document.getElementById('campaign_conversation_note_' + item.dataset.id)
            elem.remove()
          } else {
            alert("Cannot delete note")
          }
        }
      });
    }

  }
}
